<template>
  <secured-area
    :operations="['View_User', 'Create_User', 'Edit_User', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'View_Users_Rights']"
    :hideWhenAccessIsRejected="true"
  >
    <b-form>
      <b-form-row>
        <b-col>
          <form-field
            :compactMode="compactMode"
            caption="Login">
            <template #info-helper-placeholder>
                <info-helper>
                  <div><b>Login</b> = <b>Username</b> = <b>Identifiant de connexion</b></div>
                  <div>Représente le nom avec lequel l'utilisateur va pouvoir se connecter via l'écran de connexion lui demandant la saisie de son <b>Login</b> et <b>Mot de passe</b></div>
                </info-helper>
            </template>
            <b-form-input type="text" :readonly="isReadonly('username')"  v-model="username" required @change="loginname_change" />
          </form-field>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col md="6">
          <form-field :compactMode="compactMode" :required="true" caption="Nom"
            :invalidFeedback="invalidLastnameMessage"
            :state="isLastnameValid"
          >
            <b-form-input type="text" :readonly="isReadonly('lastname')" v-model="lastname" :state="isLastnameValid" required @change="lastname_change"/>
          </form-field>
        </b-col>
        <b-col md=6>
          <form-field :compactMode="compactMode" :required="true" caption="Prénom"
            :invalidFeedback="invalidFirstnameMessage"
            :state="isFirstnameValid"
          >
            <b-form-input type="text" :readonly="isReadonly('firstname')" v-model="firstname" :state="isFirstnameValid" required @change="firstname_change" />
          </form-field>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <form-field :compactMode="compactMode" :required="true" caption="Courriel"
            :invalidFeedback="invalidEmailMessage"
            :state="isEmailValid"
          >
            <template #info-helper-placeholder>
            <info-helper>Adresse e-mail (courriel) de l'utilisateur</info-helper>
            </template>
              <b-form-input type="email" :readonly="isReadonly('email')" v-model="email" :state="isEmailValid" required @change="email_change" aria-describedby="email-form-text"/>
              <b-form-text v-show="false" id="email-form-text">Sera utilisée en cas de mot de passe oublié.</b-form-text>
          </form-field>
        </b-col>
      </b-form-row>

      <error-summary v-model="validationErrors" />

      <form-bottom-action-bar>
         <template #right>
          <b-button type="reset" v-show="!readonly" :disabled="!hasChanged">Annuler</b-button>
          <b-button type="submit" v-show="!readonly" :disabled="!hasChanged">Enregistrer</b-button>
        </template>
      </form-bottom-action-bar>
    </b-form>
  </secured-area>
</template>

<script>
// #region Imports
import { mapActions } from 'vuex';
import InfoHelper from '@/components/InfoHelper.vue';
import SecuredArea from '@/components/Security/SecuredArea.vue';
import FormField from '@/components/Panels/FormField.vue';
import FormBottomActionBar from '@/components/FormBottomActionBar';
import ErrorSummary from '../../Panels/ErrorSummary.vue';
// #endregion Imports

export default{
  components: { InfoHelper, SecuredArea, FormField, FormBottomActionBar, ErrorSummary },
  props: {
    editedUser: { type:Object, default: null },
    compactMode: { type:Boolean, default:false},
    readonly: { type:Boolean, default:true},
  },
   model: {
    prop: 'editedUser',
  },
  data(){
    return {
      validationErrors: [],
      userCanEdit: false,
      userCanView: false,
      userCanAddRole: false,
      userCanRemoveRole: false,
      //lastRightsCheck: null,

      /* >> Buffering data */
      id: this.editedUser.id,

      username: this.editedUser.username || '',
      lastname: this.editedUser.lastname || '',
      firstname: this.editedUser.firstname || '',
      email: this.editedUser.email || '',
      nickname: this.editedUser.nickname || '',
      isActive: this.editedUser.isActive || 1,
      accountExpirationDate: this.editedUser.accountExpirationDate || null,
      accountNeverExpire: this.editedUser.accountNeverExpire || 1,
      /* Buffering data << */

      isLoginValid: true, loginMaxLength: 100,
      passwordMaxLength: 50,
      isLastnameValid: true, lastnameMaxLength: 100,
      isFirstnameValid: true, firstnameMaxLength: 100,
      isEmailValid: true, emailMaxLength:320,
      isNicknameValid: true, nicknameMaxLength: 100,
      //hasChanged:false,
    }
  },
  computed:{
    /*isLoginValid(){
      return !(this.username.trim().length > 0 && this.username.trim().length <= this.usernameMaxLength) ? false : null;
    },*/
    invalidLoginMessage(){
      var trimmed = this.username.trim();
      if(trimmed.length == 0)
        return "Le Login est requis !";
      if (trimmed.length > this.usernameMaxLength)
        return `Le Login ne peut pas excéder ${this.usernameMaxLength} caractères.`;
      return "Ce nom n'est pas valide.";
    },
    /*isLastnameValid(){
      return !(this.lastname.trim().length > 0 && this.lastname.trim().length <= this.lastnameMaxLength) ? false : null;
    },*/
    invalidLastnameMessage(){
      var trimmed = this.lastname.trim();
      if(trimmed.length == 0)
        return "Le nom est requis !";
      if (trimmed.length > this.lastnameMaxLength)
        return `Le nom ne peut pas excéder ${this.lastnameMaxLength} caractères.`;
      return "Ce nom n'est pas valide.";
    },
    /*isFirstnameValid(){
      return !(this.firstname.trim().length > 0 && this.firstname.trim().length <= this.firstnameMaxLength) ? false: null;
    },*/
    invalidFirstnameMessage(){
      var trimmed = this.firstname.trim();
      if(trimmed.length == 0)
        return "Le prénom est requis !";
      if (trimmed.length > this.firstnameMaxLength)
        return `Le prénom ne peut pas excéder ${this.firstnameMaxLength} caractères.`;
      return "Ce prénom n'est pas valide.";
    },
    /*isEmailValid(){
      return !(this.email.trim().length > 0 && this.email.trim().length <= this.emailMaxLength) ? false: null;
    },*/
    invalidEmailMessage(){
      var trimmed = this.email.trim();
      if(trimmed.length == 0)
        return "Le courriel est requis !";
      if(trimmed.length > this.emailMaxLength)
        return `Le courriel ne peut pas excéder ${this.emailMaxLength} caractères.`;
      return "Ce courriel n'est pas une adresse mail valide.";
    },
    getBuffer()
    {
      return { id: this.id, username: this.username, lastname: this.lastname, firstname: this.firstname, email: this.email, nickname: this.nickname, isActive: this.isActive, accountExpirationDate: this.accountExpirationDate, accountNeverExpire: this.accountNeverExpire};
    },
    hasChanged(){
      var current = JSON.stringify(this.getBuffer);
      var init = JSON.stringify(this.editedUser);
      console.log('hasChanged', current, init);
      return current != init;
    }
  },
  methods: {
    ...mapActions('auth', ['userCan']),
    async refreshRights(){
      this.userCanVview = await this.userCan('View_User');
      this.userCanEdit = await this.userCan('Edit_User');
      this.userCanCreate = await this.userCan("Create_User");
      this.userCanAddRole = await this.userCan("Add_User_To_Role");
      this.userCanRemoveRole = await this.userCan('Remove_User_From_Role');
      return true;
    },
    isReadonly(field){
      return this.readonly || (field.toLowerCase() == 'username');
/*      if(this.editedUser == null){
        return !this.userCanCreate;
      } else {
        if(field.toLowerCase() == 'username'){
          return true;
        }
        return !this.userCanEdit;
      }*/
    },
    loginname_change(){
      this.isLoginValid = !(this.username.trim().length > 0 && this.username.trim().length <= this.usernameMaxLength) ? false : null;
    },
    lastname_change(){
      this.isLastnameValid = !(this.lastname.trim().length > 0 && this.lastname.trim().length <= this.lastnameMaxLength) ? false : null;
    },
    firstname_change(){
      this.isFirstnameValid = !(this.firstname.trim().length > 0 && this.firstname.trim().length <= this.firstnameMaxLength) ? false: null;
    },
    nickname_change(){
      this.isNicknameValid = !(this.email.trim().length > 0 && this.email.trim().length <= this.emailMaxLength) ? false: null;
    },
    email_change(){
      this.isEmailValid = !(this.email.trim().length > 0 && this.email.trim().length <= this.emailMaxLength) ? false: null;
    },
    initEmptyBuffer(){
      /*return {
        username: '',
        firstname: '',
        lastname: '',
        email: '',
        nickname: '',
        isActive: '',
        accountExpirationDate: null,
        accountNeverExpire: false,
        roles: [],
      }*/
      this.username = '';
      this.lastname = '';
      this.firstname = '';
      this.email = '';
      this.nickname = '';
    },
    initBuffer(value = null){
      this.username = (value == null) ? '' : value.username;
      this.lastname = (value == null) ? '' : value.lastname;
      this.firstname = (value == null) ? '' : value.firstname;
      this.email = (value == null) ? '' : value.email;
      this.nickname = (value == null) ? '' : value.nickname;
    },
    onSubmit(e){
      console.log('onSubmit', e);
      //this.$emit('submit', this.userBuffer);
      this.$emit('submit', this.getBuffer());
    },
    onRreset(e){
      console.log('onReset', e);
      this.$emit('reset');
    }
  },
  watch: {
    editedUser: function(newVal, oldVal) { // watch it
      console.log('Detail (user) user changed: ', newVal, ' | was: ', oldVal);
      this.initBuffer(newVal);
//      this.user = newVal;
/*
      this.user = (newVal == null) ? this.initEmptyBuffer() : newVal;
      this.username = this.user.username;
      this.userBuffer = (newVal == null) ? this.initEmptyBuffer() : newVal;*/
    }
  },
  async mounted(){
    await this.refreshRights();
    /*this.userBuffer = (this.user == null) ? this.initEmptyBuffer() : this.user;
    this.username = this.userBuffer.username;*/
    this.initBuffer(this.editedUser);
    //console.log('Detail (user) mounted', this.user, this.userBuffer)
  }
}
</script>

<style scoped>
  div.input-group { margin-top: 5px; margin-bottom:5px; margin-left: 15px; width: calc(100% - 30px); }
  div.input-group.required .input-group-text::after { content:'* '; color:var(--ffvlAlert);}
</style>
